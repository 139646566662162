import TYPES from '@/types';
import { v4 } from 'uuid';

// Application
import InvestmentSuggestionQuery
  from '@/modules/investment-suggestion/application/queries/investment-suggestion-query';
import InvestmentSuggestionCommand
  from '@/modules/investment-suggestion/application/commands/investment-suggestion-command';

// Domain
import {
  InvestmentSuggestionDto,
} from '@/modules/investment-suggestion/domain/dtos/investment-suggestion-dto';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class FlagshipDefineGoalsDialogViewModel {
  @Inject(TYPES.GET_INVESTMENT_SUGGESTION_QUERY)
  private readonly get_investment_suggestion_query!: InvestmentSuggestionQuery;

  @Inject(TYPES.CREATE_INVESTMENT_SUGGESTION_COMMAND)
  private readonly create_investment_suggestion_command!: InvestmentSuggestionCommand;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  readonly message_notifier!: MessageNotifier;

  readonly i18n_namespace = 'components.my-investment-dashboard.flagship-define-goals-dialog.flagship_define_goals_dialog';

  private investment_suggestion_dto: InvestmentSuggestionDto = {
    id: v4(),
    investment_suggestion_analytic_id: '',
    action: 'flagship',
  }

  show_flagship_profiling_modal = false;

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  showFlagshipProfilingModal = async () => {
    await this.obtainInvestmentSuggestion();
    this.show_flagship_profiling_modal = true;
  }

  obtainInvestmentSuggestion = async () => {
    try {
      const { id, must_be_shown } = await this.get_investment_suggestion_query.execute();
      if (must_be_shown) {
        this.investment_suggestion_dto.investment_suggestion_analytic_id = id;
        await this.createInvestmentSuggestionAction();
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.obtain_investment_suggestion'));
    }
  }

  createInvestmentSuggestionAction = async () => {
    try {
      await this.create_investment_suggestion_command.execute(this.investment_suggestion_dto);
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.create_investment_suggestion_action'));
    }
  }
}
