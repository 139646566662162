

































































import { Component, PropSync, Vue } from 'vue-property-decorator';
import FlagshipDefineGoalsDialogViewModel
  from '@/vue-app/view-models/components/my-investment-dashboard-v2/flagship-define-goals-dialog-view-model';

@Component({
  name: 'FlagshipDefineGoalsDialog',
  components: {
    FlagshipProfilingModalSteps: () => import('@/vue-app/components/flagship/flagship-profiling/FlagshipProfilingModalSteps.vue'),
  },
})
export default class FlagshipDefineGoalsDialog extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  flagship_define_goals_dialog_view_model = Vue.observable(
    new FlagshipDefineGoalsDialogViewModel(),
  );

  closeModal() {
    this.synced_is_open = false;
  }
}
